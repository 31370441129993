import {
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  chakra,
  useToken,
  useTheme,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Select, {
  components,
  ControlProps,
  GroupBase,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  Props,
} from 'react-select';

const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ControlProps<Option, IsMulti, Group>,
) => {
  const {
    children,
    isDisabled,
    innerRef,
    innerProps,
    selectProps,
    isMulti,
    getStyles,
  } = props;

  const theme = useTheme();
  const [color] = useToken<string>('colors', [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    theme.components.Input.defaultProps.focusBorderColor,
  ]) as string[];
  return (
    <Input
      as="div"
      size={selectProps.size}
      isDisabled={isDisabled}
      height={isMulti ? 'auto' : undefined}
      _focusWithin={{
        zIndex: 2,
        borderColor: color,
        boxShadow: `0 0 0 1px ${color}`,
      }}
      // https://github.com/chakra-ui/chakra-ui/issues/2687
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={innerRef}
      pl={1.5}
      pr={10}
      data-testid={selectProps['data-testid']}
      styles={getStyles('control', props)}
      {...innerProps}
    >
      {children}
    </Input>
  );
};

const MultiValueContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueGenericProps<Option, IsMulti, Group>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { innerProps, selectProps, ...rest } = props;
  return <Tag {...rest} size={selectProps.size} mb={1} mt={1} mr={1} />;
};

const MultiValueLabel = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueGenericProps<Option, IsMulti, Group>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { innerProps, selectProps, ...rest } = props;
  return <TagLabel {...rest} />;
};

const MultiValueRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  innerProps: { className, ...rest },
}: MultiValueRemoveProps<Option, IsMulti, Group>) => {
  // the ref is for HTMLDivElement, but the element we're using is a button, so...
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <TagCloseButton {...rest} />;
};

const IndicatorsContainer = (props: any) => {
  return (
    <chakra.div
      position="absolute"
      right="0"
      top="50%"
      transform="translateY(-50%)"
    >
      <components.IndicatorsContainer {...props} />
    </chakra.div>
  );
};

export default function ChakraSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ id, styles, ...props }: Props<Option, IsMulti, Group>) {
  const { t } = useTranslation('backoffice');

  return (
    <Select
      placeholder={t('select_option', 'Valitse...')}
      noOptionsMessage={() => t('no_options', 'Ei valintoja')}
      styles={{
        ...styles,
        menu: (baseStyles, menuProps) => {
          const customStyles = {
            ...baseStyles,
            zIndex: 2,
          };

          return styles?.menu
            ? styles.menu(customStyles, menuProps)
            : customStyles;
        },
      }}
      components={{
        Control,
        MultiValueContainer,
        MultiValueLabel,
        MultiValueRemove,
        IndicatorsContainer,
      }}
      inputId={id}
      {...props}
    />
  );
}
