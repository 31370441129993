import { ReactElement } from 'react';
import { Field, FieldMetaState, FieldRenderProps } from 'react-final-form';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Tooltip,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useTranslation, TFunction } from 'next-i18next';
import { EyeSlash, Question } from '@phosphor-icons/react';

interface FieldWrapperProps {
  name: string;
  label?: string;
  labelHint?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: (value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (value: any, name: string) => any;
  type?: 'text' | 'email' | 'tel' | 'number' | 'checkbox' | 'radio' | 'date';
  allowNull?: boolean;
  helpText?: string | ReactElement;
  isPrivate?: boolean; // "private" would be better but it's a reserved word :(
  errorParser?: (meta: FieldMetaState<any>, t: TFunction) => any;
  children({
    input,
    meta,
  }: Pick<FieldRenderProps<any>, 'input' | 'meta'>): ReactElement;
}

export const HelpText: React.FC<{ helpText: string | ReactElement }> = ({
  helpText,
}) => (
  <Tooltip hasArrow label={helpText} aria-label="Tooltip">
    <Icon
      width="18px"
      height="18px"
      as={Question}
      weight="light"
      color="#999"
    />
  </Tooltip>
);

export const PrivateIcon = () => {
  const { t } = useTranslation('backoffice');

  return (
    <Tooltip
      hasArrow
      label={t('field_is_private', 'Tietoa ei julkaista myynti-ilmoitukseen')}
      aria-label="Tooltip"
    >
      <Icon
        as={EyeSlash}
        width="16px"
        height="16px"
        weight="light"
        color="#999"
      />
    </Tooltip>
  );
};

// Override RFF's default parse with this identity function
// so it doesn't remove keys from the POSTed data object
// when the value is an empty string.
// Insted, we allow empty strings to be stored into the
// data model and submitted to the backend so we're able
// to overwrite existing data with empty values.
const anyParse = (value: any): any => {
  return value;
};

const nullParse = (value: any) => (value === '' ? null : value);

export const extractErrorFromMeta = (
  meta: FieldMetaState<any>,
  t: TFunction,
) => {
  /* String errors */
  if (typeof meta.error === 'string' || typeof meta.submitError === 'string') {
    return t(`${meta.error || meta.submitError}`);
  }

  /* Schema error with attached data */
  if (typeof meta.error === 'object' && meta.error.key && meta.error.values) {
    return t(meta.error.key, meta.error.values);
  }

  /* Nested schema errors in objects */
  if (typeof meta.error === 'object') {
    return Object.values(meta.error).map(
      (e: any) => typeof e === 'string' && t(e),
    );
  }

  return meta.error;
};

const FieldWrapper = ({
  name,
  label,
  labelHint,
  children,
  parse,
  format,
  type,
  helpText,
  isPrivate = false,
  allowNull = false,
  errorParser = extractErrorFromMeta,
  ...rest
}: FieldWrapperProps & FormControlProps) => {
  const { t } = useTranslation('backoffice');

  const defaultParse = ['number', 'date'].includes(type || '')
    ? nullParse
    : anyParse;

  return (
    <Field
      name={name}
      allowNull={allowNull}
      parse={parse || defaultParse}
      format={format}
      type={type}
      render={({ input, meta }) => (
        <FormControl
          colorScheme="primary"
          isInvalid={Boolean(
            meta.touched &&
              (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)),
          )}
          pb="3"
          {...rest}
        >
          {label && (
            <FormLabel
              id={`${name}_label`}
              htmlFor={name}
              display="flex"
              alignItems="center"
              sx={{ '& > *': { marginLeft: '0.5em' } }}
            >
              {label}
              {labelHint ? (
                <Text color="gray.500" fontWeight="normal">
                  {labelHint}
                </Text>
              ) : null}
              {helpText && <HelpText helpText={helpText} />}
              {isPrivate && <PrivateIcon />}{' '}
            </FormLabel>
          )}
          {children && children({ input: { ...input, id: name }, meta })}
          <FormErrorMessage pos="absolute" mt=".125em">
            {errorParser(meta, t)}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default FieldWrapper;
