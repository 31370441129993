import { isAfter, isBefore, isValid, parse } from 'date-fns';
import * as Yup from 'yup';
import formatDate from './formatDate';

declare module 'yup' {
  interface IsDateStringOptions {
    format?: string;
    max?: Date;
    min?: Date;
  }
  interface StringSchema {
    isDateString(options?: IsDateStringOptions, message?: string): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'isDateString',
  function test(options?: Yup.IsDateStringOptions, message?: string) {
    return this.test(
      'is-date-string',
      message || 'validation.not_a_date_string',
      (value, { path }) => {
        const { format, max, min } = {
          format: 'yyyy-MM-dd',
          ...options,
        };
        try {
          if (typeof value !== 'string') {
            // don't fail this particular validation, but pass to next
            return true;
          }

          const parsedDate = parse(value, format, 0);

          if (!isValid(parsedDate)) {
            return new Yup.ValidationError(message as string, value, path);
          }
          if (min && isBefore(parsedDate, min)) {
            return new Yup.ValidationError(
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                key: 'validation.date_too_small',
                values: {
                  min: formatDate(min),
                },
              },
              value,
              path,
            );
          }
          if (max && isAfter(parsedDate, max)) {
            return new Yup.ValidationError(
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                key: 'validation.date_too_large',
                values: {
                  max: formatDate(max),
                },
              },
              value,
              path,
            );
          }
          return true;
        } catch (e) {
          return false;
        }
      },
    );
  },
);

export const stringMax = (maxLength: number) =>
  Yup.string()
    .max(maxLength, ({ max }) => ({
      key: 'validation.value_too_long',
      values: { max },
    }))
    .nullable();
export const postalCode = Yup.string()
  .matches(/^\d{5}$/, {
    message: 'validation.invalid_postal_code',
  })
  .nullable();
export const email = stringMax(50).email('validation.invalid_email').nullable();
export const phoneNumber = stringMax(20)
  .matches(/^\+?[ \d]{7,}$/, {
    message: 'validation.invalid_phone_number',
  })
  .nullable();
export const propertyId = Yup.string()
  // Property id can be either 14 digits or
  // four times 1-3 digits separated by hyphens
  .matches(/(^(\d{1,3}-){3}\d{1,3}$)|(^\d{14}$)/, {
    message: 'validation.invalid_property_id',
  })
  .nullable();

export default Yup;
