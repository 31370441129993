import { useMemo, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Form } from 'react-final-form';
import {
  ButtonGroup,
  Button,
  Input,
  Stack,
  CheckboxGroup,
  Checkbox,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import groupBy from 'lodash.groupby';
import { Reference } from '@apollo/client';
import ChakraSelect from '@/components/common/ChakraSelect';
import FieldWrapper from '@/components/common/FieldWrapper';
import Schema, { SchemaType, TilavahtiDbSchema } from './schema';
import useValidationSchema from '@/lib/hooks/useValidationSchema';
import {
  ListingWatchAttributesFragmentDoc,
  useCreateListingWatchMutation,
} from '@/generated/graphql';
import {
  getMatchingRegionOptionsProps,
  RegionType,
  getRegionOptionPropsGrouped,
} from '@/lib/regions';
import ButtonLink from '../ButtonLink';
import { urls } from '@/lib/urls';
import { onAnalyticsFormEvent } from '@/lib/utils/analytics';
import { TilavahtiDefaultValues, TilavahtiFormProps } from '@/types/tilavahti';

const initialValues: SchemaType = {
  email: '',
  location: [],
  types: [],
  allow_marketing: false,
  access_token: null,
};

const getLocationItem = (value: string, type: string) => ({
  value,
  type: type as RegionType,
});

const createInitialValues = (
  defaultValues: TilavahtiDefaultValues,
  locale = 'fi',
): SchemaType => {
  if (!defaultValues) {
    return initialValues;
  }

  const { filters, ...rest } = defaultValues;

  let customInitialValues: Partial<SchemaType> = {
    ...rest,
  };

  if (filters) {
    const { property_type, municipality, province } = filters;

    const locations: { type: RegionType; value: string }[] = [
      ...(typeof municipality === 'string'
        ? [getLocationItem(municipality, 'municipality')]
        : municipality?.map((m) => getLocationItem(m, 'municipality')) || []),
      ...(typeof province === 'string'
        ? [getLocationItem(province, 'province')]
        : province?.map((p) => getLocationItem(p, 'province')) || []),
    ];

    customInitialValues = {
      // eslint-disable-next-line no-nested-ternary
      types: property_type
        ? typeof property_type === 'string'
          ? [property_type]
          : property_type
        : initialValues.types,
      // value is on default language and not translated, but label is translated.
      location: locations?.length
        ? locations.map(({ value, type }) => ({
            value,
            label: getMatchingRegionOptionsProps({
              value,
              type,
              locale,
            })?.label,
            type,
          }))
        : initialValues.location,
    };
  }

  return {
    ...initialValues,
    ...customInitialValues,
  };
};

const TilavahtiForm = ({
  propertyTypeOptions,
  defaultValues,
  onClose,
  onCloseBtnText,
}: TilavahtiFormProps) => {
  const { locale } = useRouter();
  const toast = useToast();
  const { t, i18n } = useTranslation('tilavahti');
  const validate = useValidationSchema(Schema);
  const [submitSucceeded, setSubmitSucceeded] = useState<boolean>();
  const [createListingWatch] = useCreateListingWatchMutation();
  const locationOptions = getRegionOptionPropsGrouped(locale);
  const resolvedInitialValues = useMemo(
    () => createInitialValues(defaultValues, locale),
    [defaultValues, locale],
  );

  const onSubmit = async (values: SchemaType) => {
    try {
      const { municipality: municipalities = [], province: provinces = [] } =
        groupBy(values.location, 'type');

      const { data } = await createListingWatch({
        variables: {
          access_token: values.access_token,
          email: values.email,
          language: i18n.language,
          municipalities: municipalities.map((m) => m.value),
          provinces: provinces.map((p) => p.value),
          property_types: values.types,
          marketing_consent: values.allow_marketing ?? false,
        },
        // Don't store anything to the cache automatically,
        // instead add to the cache below when needed
        fetchPolicy: 'no-cache',
        update(cache, { data: createdData }) {
          // Only add to the cache when we got some data back from
          // the creation mutation, which means that it's an existing
          // user adding another tilavahti to their subscription
          if (
            !(
              createdData?.add_listing_watch?.success &&
              createdData?.add_listing_watch?.value
            )
          ) {
            return;
          }

          // validate data and get a typed object back instead of json
          const createdValues = TilavahtiDbSchema.validateSync(
            createdData.add_listing_watch.value,
          );

          // add the newly created listing watch to the cache
          const newListingWatchRef = cache.writeFragment({
            id: `listing_watch:{"access_token":"${createdValues.access_token}"}`,
            fragment: ListingWatchAttributesFragmentDoc,
            data: {
              __typename: 'listing_watch',
              ...createdValues,
            },
          });

          // also add it to the list of the current user's listing watches
          cache.modify({
            id: cache.identify({
              __typename: 'listing_watch_subscriber',
              access_token: values.access_token,
            }),
            fields: {
              listing_watches(existing: Reference[] = []) {
                return [...existing, newListingWatchRef];
              },
              marketing_consent() {
                return !!createdValues.marketing_consent;
              },
            },
          });
        },
      });

      onAnalyticsFormEvent('submission_success', { name: 'Tilavahti' });
      setSubmitSucceeded(data?.add_listing_watch?.success);
    } catch (error) {
      onAnalyticsFormEvent('submission_failed', { name: 'Tilavahti' });
      console.error('Tilavahti submit error: ', error);
      Sentry.captureException(error);

      toast({
        title: t('toast.error', 'Virhe'),
        description: t(
          'toast.error_message',
          'Tapahtui virhe, yritä uudelleen',
        ),
        status: 'error',
        duration: 10000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  return (
    <Form<SchemaType>
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur={false}
      initialValues={resolvedInitialValues}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        values,
        form,
      }) => (
        <>
          {submitSucceeded ? (
            <VStack spacing={10} mt={10}>
              <Heading as="h4" size="md">
                {t('tilavahti_saved', 'Tilavahtisi on tallennettu')}
              </Heading>
              {!defaultValues?.email ? (
                <Text>
                  {t(
                    'tilavahti_saved_description',
                    'Saat kohta sähköpostiviestin Tilavahdin vahvistusta varten.',
                  )}
                </Text>
              ) : null}

              <Stack pb={[3, 10]} direction={['column', 'row']}>
                {onClose ? (
                  <Button
                    colorScheme="primary"
                    mr={[0, 3]}
                    onClick={() => onClose()}
                  >
                    {onCloseBtnText ??
                      t('return_to_searchpage', 'Palaa hakusivulle')}
                  </Button>
                ) : (
                  <ButtonLink
                    colorScheme="primary"
                    link={{
                      title: t('return_to_searchpage', 'Palaa hakusivulle'),
                      url: urls.public.buy.index,
                      mr: [0, 3],
                    }}
                  />
                )}
                <Button
                  colorScheme="primary"
                  onClick={() => {
                    form.restart(resolvedInitialValues);
                    setSubmitSucceeded(false);
                  }}
                >
                  {t('add_new_tilavahti', 'Lisää uusi tilavahti')}
                </Button>
              </Stack>
            </VStack>
          ) : (
            <form onSubmit={handleSubmit} noValidate>
              <Text>
                {t(
                  'ingress',
                  'Tilavahdin avulla saat tiedon uusista hakuehtojasi vastaavista kohteista sähköpostiisi.',
                )}
              </Text>
              <Stack mt={5} spacing={5}>
                <FieldWrapper
                  name="email"
                  label={t('email', 'Sähköpostiosoite')}
                  isRequired
                  isDisabled={!!defaultValues?.email}
                >
                  {({ input }) => <Input {...input} />}
                </FieldWrapper>
                <Heading fontSize="xl" as="h4">
                  {t('search_criteria', 'Hakuehtosi')}
                </Heading>
                <FieldWrapper
                  isRequired
                  name="location"
                  label={t('location', 'Sijanti')}
                >
                  {({ input }) => (
                    <ChakraSelect
                      {...input}
                      instanceId="tilavahti-select"
                      isMulti
                      options={locationOptions}
                      data-testid="location-select"
                    />
                  )}
                </FieldWrapper>
                <FieldWrapper
                  name="types"
                  label={t('types', 'Kohdetyyppi')}
                  type="checkbox"
                  isRequired
                >
                  {({ input }) => (
                    <CheckboxGroup
                      {...input}
                      value={values.types}
                      defaultValue={initialValues.types}
                    >
                      <SimpleGrid columns={[1, 2]}>
                        {propertyTypeOptions.map(({ value, label }) => (
                          // FormControl passes isRequired which is not dynamic, so override that. Use isRequired in Formcontrol for the label only. Validation is done by app
                          <Checkbox
                            value={value}
                            key={value}
                            isRequired={false}
                          >
                            {label}
                          </Checkbox>
                        ))}
                      </SimpleGrid>
                    </CheckboxGroup>
                  )}
                </FieldWrapper>
                <FieldWrapper name="allow_marketing" type="checkbox">
                  {({ input }) => (
                    <Checkbox {...input} isChecked={input.checked}>
                      {t(
                        'allow_marketing',
                        'Minulle saa lähettää markkinointiviestejä sähköpostiin.',
                      )}
                    </Checkbox>
                  )}
                </FieldWrapper>
                <Text>
                  {t(
                    'consent',
                    'Tallentamalla Tilavahdin annat luvan tietojesi tallentamisen Metsätilat.fi:n asiakasrekisteriin.',
                  )}
                </Text>
                <ButtonGroup spacing={4}>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={submitting}
                    isDisabled={pristine || hasValidationErrors || submitting}
                  >
                    {t('submit', 'Aseta tilavahti')}
                  </Button>
                  <Button
                    colorScheme="primary"
                    variant="link"
                    isDisabled={submitting}
                    onClick={onClose}
                  >
                    {t('cancel', 'Peruuta')}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          )}
        </>
      )}
    />
  );
};

export default TilavahtiForm;
