import Yup, { email } from '@/lib/yup';

const Schema = Yup.object({
  email: email.required('validation.is_required'),
  location: Yup.array()
    .of(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
        type: Yup.string(),
      }),
    )
    .required('validation.is_required')
    .min(1, ({ min }) => ({
      key: 'validation.field_too_short',
      values: { min },
    })),
  types: Yup.array()
    .of(Yup.string().required())
    .min(1, ({ min }) => ({
      key: 'validation.field_too_short',
      values: { min },
    }))
    .required('validation.is_required'),
  allow_marketing: Yup.boolean(),
  access_token: Yup.string().nullable().optional(),
});

export const TilavahtiDbSchema = Yup.object({
  access_token: Yup.string().required(),
  created_at: Yup.string().required(),
  email: Yup.string().required(),
  id: Yup.number().required(),
  language: Yup.string().required(),
  marketing_consent: Yup.boolean().required(),
  municipalities: Yup.array().of(Yup.string()).required(),
  provinces: Yup.array().of(Yup.string()).required(),
  property_types: Yup.array().of(Yup.string()).required(),
  updated_at: Yup.string().required(),
  verified: Yup.boolean().required(),
});

export type TilavahtiDbSchemaType = Yup.InferType<typeof TilavahtiDbSchema>;

export type SchemaType = Yup.InferType<typeof Schema>;

export default Schema;
