import { useTranslation } from 'next-i18next';
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { memo } from 'react';
import TilavahtiForm from './Form';
import { OptionProp } from '@/lib/optionProps';
import { TilavahtiModalButtonProps } from '@/types/tilavahti';

const TilavahtiModalButton = ({
  defaultValues,
  propertyTypes,
  onCloseBtnText,
  ...buttonProps
}: TilavahtiModalButtonProps) => {
  const { t } = useTranslation('tilavahti');
  const { onOpen, isOpen, onClose } = useDisclosure();

  const propertyTypeOptions: OptionProp<string>[] =
    propertyTypes?.map(({ value }) => ({
      value,
      label: t(`backoffice:property_type_values.${value}`),
    })) || [];

  return (
    <>
      <Button {...buttonProps} onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as="h3">{t('title', 'Aseta tilavahti')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={7}>
            <TilavahtiForm
              propertyTypeOptions={propertyTypeOptions}
              defaultValues={defaultValues}
              onClose={onClose}
              onCloseBtnText={onCloseBtnText}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(TilavahtiModalButton);
