import { add, startOfDay, isBefore, isAfter } from 'date-fns';
import { TFunction } from 'next-i18next';
import { ParsedUrlQuery } from 'querystring';
import sortBy from 'lodash.sortby';
import {
  Maybe,
  GetPublicListingByIdQuery,
  Listing_Public_Order_By,
  Order_By,
  GetAllPublicListingCardQueryVariables,
  Property_Bool_Exp,
} from '@/generated/graphql';
import { Filters, ListingVariable } from '@/types/listing';
import {
  municipalities,
  provinces,
  RegionLocale,
  RegionType,
} from '@/lib/regions';

export type Listing = {
  properties: {
    property_name?: Maybe<string> | undefined;
    property_type?: Maybe<string> | undefined;
    property_sub_type?: Maybe<string> | undefined;
    property_image_publics?: GetPublicListingByIdQuery['listing_public'][number]['properties'][0]['property_image_publics'];
  }[];
  sales_assignment?: GetPublicListingByIdQuery['listing_public'][number]['sales_assignment'];
};

/**
 * Produces an array of unique "tags", i.e. property types and sub types,
 * aggregated from each property in the listing.
 * @param listing   a listing object
 * @param t         an i18next translation function that have access to the `backoffice` namespace
 * @returns An array of unique tags of the form "PropertyType (PropertySubType)"
 */
export const listingTags = (listing: Listing, t: TFunction): string[] => {
  const tags = listing.properties.map(
    (property) =>
      `${
        property.property_type
          ? t(`backoffice:property_type_values.${property.property_type}`)
          : ''
      } ${
        property.property_sub_type
          ? `(${t(
              `backoffice:property_sub_type_values.${property.property_sub_type}`,
            )})`
          : ''
      }`,
  );
  return Array.from(new Set(tags));
};

type RealtorContact = NonNullable<
  GetPublicListingByIdQuery['listing_public'][number]['sales_assignment']
>['sales_assignment_users'][0]['user'];

export const realtorContact = (listing: Listing): RealtorContact => {
  if (!listing.sales_assignment?.sales_assignment_users.length) {
    throw new Error('Cannot find sales representative');
  }

  const primaryContact = listing.sales_assignment.sales_assignment_users.find(
    (u) => u.show_as_contact,
  );
  const firstContact = listing.sales_assignment.sales_assignment_users[0];
  return primaryContact?.user || firstContact.user;
};

type PropertyImage =
  GetPublicListingByIdQuery['listing_public'][number]['properties'][number]['property_image_publics'][number] & {
    property_name?: string;
  };

type PropertyImages = {
  propertyImages: Array<PropertyImage>;
  coverImage?: PropertyImage | undefined;
};

export const listingImages = (listing: Listing): PropertyImages => {
  // get all images from all properties and flatten
  let propertyImages =
    listing?.properties
      ?.map((property) => {
        // Augment the property images with the name
        // of the property they're belonging to so we can
        // show the property name as part of the caption
        const imagesWithPropertyName: PropertyImage[] = (
          property.property_image_publics || []
        ).map((img) => ({
          ...img,
          property_name: property.property_name ?? undefined,
        }));
        return sortBy(imagesWithPropertyName, 'order');
      })
      .flat()
      .filter(Boolean) || [];

  // get the first image tagged as 'cover' image and put it first
  const coverImageIndex = propertyImages.findIndex(
    (img) => img?.property_image_type === 'cover',
  );

  if (coverImageIndex >= 0) {
    propertyImages = [
      propertyImages[coverImageIndex],
      ...propertyImages.slice(0, coverImageIndex),
      ...propertyImages.slice(coverImageIndex + 1),
    ];
  }

  return {
    propertyImages,
    coverImage: propertyImages[0],
  };
};

export const getMinimumOfferDeadline = (listing: {
  publish_end_datetime?: string | null;
}) =>
  startOfDay(
    add(new Date(listing.publish_end_datetime ?? Date.now()), {
      days: 7,
    }),
  );

export const isPublic = (listing: {
  is_published?: boolean | null | undefined;
  publish_start_datetime?: string;
  publish_end_datetime?: string;
}) =>
  Boolean(
    listing.is_published &&
      listing.publish_start_datetime &&
      isAfter(new Date(), new Date(listing.publish_start_datetime)) &&
      (!listing.publish_end_datetime ||
        isBefore(new Date(), new Date(listing.publish_end_datetime))),
  );

export const createOrderBy = (
  order_by?: string | string[] | ListingVariable['order_by'],
): Partial<Listing_Public_Order_By> => {
  const orderBy = order_by && Array.isArray(order_by) ? order_by[0] : order_by;
  switch (orderBy) {
    case 'oldest':
      return { publish_start_datetime: Order_By.Asc };
    case 'ending':
    case 'expiring':
      return { publish_end_datetime: Order_By.Asc };
    case 'updated':
      return { updated_at: Order_By.Desc };
    case 'name':
      return { name: Order_By.Asc };
    case 'popular':
      return {
        listing_stats_aggregate: {
          sum: {
            views_total: Order_By.Desc,
          },
        },
      };
    case 'latest':
    default:
      return { publish_start_datetime: Order_By.Desc };
  }
};

const hasFeature = (features: Filters['features'], feature: string) => {
  if (!features || !features.length) {
    return false;
  }
  return features === feature || features.includes(feature);
};

export const createListingQueryVariables = (
  {
    municipality,
    province,
    property_type,
    area_min,
    area_max,
    price_min,
    price_max,
    order_by,
    search,
    features,
    limit,
    offset,
    page,
  }: Partial<Filters & ListingVariable> & {
    limit?: string;
    offset?: string;
    page?: string;
  },
  step?: number,
): GetAllPublicListingCardQueryVariables => {
  let propertyId;
  let freeWord;
  if (search && !Array.isArray(search)) {
    if (/^\d{3}-\d{3}-\d{4}-\d{4}$/.test(search.trim())) {
      propertyId = search.trim();
    } else {
      freeWord = search.trim();
    }
  }

  const queryProps: Pick<
    GetAllPublicListingCardQueryVariables,
    'offset' | 'limit'
  > = {};
  if (limit && Number.parseInt(limit, 10) > -1) {
    queryProps.limit = Number.parseInt(limit, 10);
    if (offset) {
      queryProps.offset = Number.parseInt(offset, 10);
    }
  } else if (page && step) {
    queryProps.limit = Number.parseInt(page, 10) * step;
  }

  const defaultVariables: GetAllPublicListingCardQueryVariables = {
    order_by: createOrderBy(order_by),
    // this where condition structure might seem redundant
    // with a lot of repeating `properties` keys, but hasura
    // works in mysterious ways, so unless they're repeated
    // like this we won't get correct search results
    where: {
      _and: [],
    },
  };

  if (freeWord) {
    defaultVariables.where?._and?.push({
      _or: [
        { name: { _ilike: `%${freeWord}%` } },
        { public_id: { _ilike: `%${freeWord}%` } },
      ],
    });
  }

  if (area_min || area_max) {
    defaultVariables.where?._and?.push({
      total_area_in_hectares: {
        ...(area_min && { _gte: area_min }),
        ...(area_max && { _lte: area_max }),
      },
    });
  }

  if (price_min || price_max) {
    defaultVariables.where?._and?.push({
      total_price: {
        ...(price_min && { _gte: price_min }),
        ...(price_max && { _lte: price_max }),
      },
    });
  }

  const propertiesExp: Property_Bool_Exp = { _and: [] };

  const municipalityProvinceExp: Property_Bool_Exp = { _or: [] };
  if (municipality) {
    municipalityProvinceExp._or?.push({
      property_municipality: {
        _in: [municipality].flat(),
      },
    });
  }
  if (province) {
    municipalityProvinceExp._or?.push({
      property_province: {
        _in: [province].flat(),
      },
    });
  }
  if (municipalityProvinceExp._or?.length) {
    propertiesExp._and?.push(municipalityProvinceExp);
  }

  if (property_type) {
    propertiesExp._and?.push({
      _or: [
        {
          property_type: {
            _in: [property_type].flat(),
          },
        },
        {
          secondary_property_types: {
            _contains: property_type,
          },
        },
      ],
    });
  }

  if (hasFeature(features, 'shoreline')) {
    propertiesExp._and?.push({
      property_has_shoreline: { _eq: true },
    });
  }

  if (hasFeature(features, 'road')) {
    propertiesExp._and?.push({
      property_has_a_road_to_destination: { _eq: true },
    });
  }

  if (propertyId) {
    propertiesExp._and?.push({
      property_id: { _eq: propertyId },
    });
  }

  if (hasFeature(features, 'plot')) {
    propertiesExp._and?.push({
      _or: [
        { property_plan_local: { _eq: true } },
        { property_plan_master: { _eq: true } },
        { property_plan_regional: { _eq: true } },
        { property_plan_shoreline: { _eq: true } },
      ],
    });
  }

  if (propertiesExp._and?.length) {
    defaultVariables.where?._and?.push({ properties: propertiesExp });
  }

  return {
    ...defaultVariables,
    ...queryProps,
  };
};

export const createUrl = (
  listingQuery: null | Filters,
  simpleObj: Record<string, string> = {},
) => {
  // URLSearchParams doesn't support arrays of strings like we would need it to, so dem have to be appended separately
  const urlParams = new URLSearchParams(simpleObj);
  if (listingQuery) {
    Object.entries(listingQuery).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          urlParams.append(key, val);
        });
      } else {
        urlParams.append(key, value);
      }
    });
  }

  return urlParams.toString();
};

type UsableQueryFilters = keyof Filters;
type UsableListingQueryParams = UsableQueryFilters | keyof ListingVariable;
export const widenedListingTerms: UsableQueryFilters[] = [
  'features',
  'waterside',
];

export const listingTerms: UsableQueryFilters[] = [
  'property_type',
  'price_max',
  'price_min',
  'area_max',
  'area_min',
  'municipality',
  'province',
  'search',
];

const locationTerms = ['municipality', 'province'];

export const listingVariables: (keyof ListingVariable)[] = ['order_by'];

export const listingParams: UsableListingQueryParams[] = [
  ...widenedListingTerms,
  ...listingTerms,
  ...listingVariables,
];

export const LOCATION_DELIMITER = ', ';

export const getLocationFilters = (filters: Filters) =>
  Object.entries(filters).reduce<Filters>(
    (acc, [key, value]) =>
      locationTerms.includes(key) ? { ...acc, [key]: value } : acc,
    {},
  );

const mergeQueries = (
  urlQuery?: null | ParsedUrlQuery,
  enforcedQuery?: null | ParsedUrlQuery,
) => {
  if (!enforcedQuery) {
    return urlQuery || {};
  }

  // If url currently sets any location term, use that instead of the default enforced
  if (urlQuery?.municipality || urlQuery?.province) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      municipality: _,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      province: __,
      ...applicapleFilters
    } = enforcedQuery;
    return { ...applicapleFilters, ...urlQuery };
  }

  // Url state supersedes default/enforced
  return { ...enforcedQuery, ...urlQuery };
};

// Listing query is an object used to query listings, but NOT to be used for URL state eg.
export const createListingQuery = (
  urlQuery?: null | ParsedUrlQuery,
  enforcedQuery?: null | ParsedUrlQuery,
) => {
  const query = mergeQueries(urlQuery, enforcedQuery);

  return (
    Object.entries(query)
      // Next router requires [] to handle as array. Key will be 'foo[]' instead of 'foo'
      .filter(([k]) =>
        listingParams.includes(
          k?.replace('[]', '') as UsableListingQueryParams,
        ),
      )
      .reduce((acc, [k, value]) => {
        if (!value) {
          return acc;
        }

        return {
          ...acc,
          [k?.replace('[]', '')]: Array.isArray(value)
            ? value.map((s) => decodeURIComponent(s))
            : decodeURIComponent(value || ''),
        };
      }, {} as Filters & ListingVariable)
  );
};

// Listing Filters is basically the state
export const createListingFilters = createListingQuery;

interface LocationFilter {
  type: RegionType | 'search';
  value: string;
}

export const parseLocationToFilters = (
  location: string,
  locale: RegionLocale = 'fi',
): null | LocationFilter => {
  // Location is basically `Province${LOCATION_DELIMITER}Municipality` or `Province`
  const [province, municipality] = location.trim().split(LOCATION_DELIMITER);
  if (!province && !municipality) {
    return null;
  }

  const validMatchingLocation = [...municipalities, ...provinces].find(
    ({ name: { fi, sv } }) =>
      [fi.toLowerCase(), sv.toLowerCase()].find((area) => {
        const match = (municipality || province).toLowerCase();
        return area.startsWith(match);
      }),
  );

  if (validMatchingLocation) {
    return {
      type: validMatchingLocation.type,
      value: validMatchingLocation.name[locale],
    };
  }

  // return whatever else was given as a free search string
  return {
    type: 'search',
    value: location.trim(),
  };
};

export const isLocationFilterSet = (
  loc: LocationFilter,
  query: ParsedUrlQuery,
) => {
  const queryValue = query[loc.type];
  if (!queryValue) {
    return false;
  }
  return typeof queryValue === 'string'
    ? queryValue === loc.value
    : queryValue.includes(loc.value);
};
