import * as Sentry from '@sentry/nextjs';
import { FORM_ERROR, setIn } from 'final-form';
import { AnySchema, ValidationError } from 'yup';
import { i18n } from 'next-i18next';

/**
 * Sets the `innerError.message` in an `errors` object at the key
 * defined by `innerError.path`.
 * @param {Object} errors The object to set the error in.
 * @param {Yup.ValidationError} innerError A `yup` field error.
 * @returns {Object} The result of setting the new error message onto `errors`.
 */
const setInError = (
  errors: Record<string, unknown>,
  innerError: ValidationError,
): Record<string, unknown> => {
  if (innerError.path) {
    return setIn(errors, innerError.path, innerError.message) as Record<
      string,
      unknown
    >;
  }
  return errors;
};

/**
 * Takes a `yup` validation schema and returns a function that expects
 * a map of values to validate. If the validation passes, the function returns an empty object
 * (signalling that the values are valid). If the validation doesn't pass, it returns
 * a map of invalid field names to errors.
 * @param {AnySchema} schema `yup` schema definition.
 * @returns {(values:Record<string, unknown>) => Record<string, unknown>} A function that expects some `values`
 *  and returns either an empty object or a map of field names to error messages.
 */
const useValidationSchema =
  (schema: AnySchema) => (values: Record<string, unknown>) => {
    try {
      schema.validateSync(values, { abortEarly: false });
      return {};
    } catch (err) {
      if (ValidationError.isError(err)) {
        // build an object that resembles the form state, with
        // error messages as values
        const errors: Record<string, unknown> = err.inner.reduce(
          setInError,
          {},
        );

        // return the errors object
        return errors;
      }
      console.error(err);
      Sentry.captureException(err);
      return {
        [FORM_ERROR]: i18n
          ? i18n.t(
              'backoffice:unknown_validation_error',
              'Tuntematon validointivirhe.',
            )
          : 'Tuntematon validointivirhe.',
      };
    }
  };

/**
 * Takes a `yup` validation schema and returns a function that expects
 * a map of values to validate. If the validation passes, the function resolves to an empty object
 * (signalling that the values are valid). If the validation doesn't pass, it resolves
 * to a map of invalid field names to errors.
 * @param {AnySchema} schema `yup` schema definition.
 * @returns {(values: Record<string, unknown>) => Promise<Record<string, unknown>>} A function that expects some `values`
 *  and resolves to either an empty object or a map of field names to error messages.
 */
export const useValidationSchemaAsync =
  (schema: AnySchema) => (values: Record<string, unknown>) =>
    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        return Promise.resolve({});
      })
      .catch((err) => {
        if (ValidationError.isError(err)) {
          // build an object that resembles the form state, with
          // error messages as values
          const errors: Record<string, unknown> = err.inner.reduce(
            setInError,
            {},
          );

          // return a resolved promise with the error object
          return Promise.resolve(errors);
        }
        Sentry.captureException(err);
        return Promise.resolve({
          [FORM_ERROR]: i18n
            ? i18n.t(
                'backoffice:unknown_validation_error',
                'Tuntematon validointivirhe.',
              )
            : 'Tuntematon validointivirhe.',
        });
      });

export default useValidationSchema;
