import { GaEvent } from '@/types';

type MtGaEventType = 'form';
/*
 * NOTE that while dataLayer is declared, it's not necessarily defined in case analytics is not loaded.
 * Analytics is not loaded in case no cookie consent
 */

const event_prefix = 'mt_'; // Prefix all the events for separation concerns (GTM does events, Leadoo does events etc.)

const gaEvent = (event: MtGaEventType, { category, label, value }: GaEvent) => {
  try {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `${event_prefix}${event}`,
        [`${event_prefix}event_label`]: label,
        [`${event_prefix}event_category`]: category,
        [`${event_prefix}event_value`]: value,
      });
    }
  } catch (error) {
    /* analytics can fail pretty silently */
    console.error(error);
  }
};

export const onAnalyticsFormEvent = (
  category: 'submission_success' | 'submission_failed' | 'validation_error',
  formEvent: { name: string; errorFields?: string[] },
) => {
  gaEvent('form', {
    category,
    label: formEvent.name,
    value: formEvent?.errorFields?.join(', '),
  });
};

export default { onAnalyticsFormEvent };
